import { useTranslate } from '/machinery/I18n'
import { useSpring, animated } from '@react-spring/web'
import { ImageCover } from '/features/buildingBlocks/Image'
import { ContainerSm } from '/features/buildingBlocks/Container'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import { TextXl } from '/features/buildingBlocks/Text'
import { ScrollIndicator } from '/features/buildingBlocks/ScrollIndicator'

import styles from './HeroSecondary.css'

export function HeroSecondary({ layoutClassName, image, text, title }) {
  const { __ } = useTranslate()
  const springConfig = {
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(40px)' },
    config: { tension: 100, friction: 35 },
  }

  const headingSpring = useSpring({ ...springConfig, delay: 200 })
  const textSpring = useSpring({ ...springConfig, delay: 400 })
  const scrollIndicatorSpring = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-20px)' },
    config: { tension: 100, friction: 35 },
    delay: 600,
  })

  return (
    <header className={cx(styles.component, layoutClassName)} data-x='hero'>
      <ContainerSm layoutClassName={styles.textContainer}>
        <div className={styles.content}>
          {title && (
            <animated.div className={styles.heading} style={headingSpring}>
              <HeadingXl h='1'>{title}</HeadingXl>
            </animated.div>
          )}
          {text && (
            <animated.div style={textSpring}>
              <TextXl>{text}</TextXl>
            </animated.div>
          )}
          <animated.div
            style={scrollIndicatorSpring}
            className={styles.scrollIndicator}
          >
            <ScrollIndicator title={__`discover-more`} />
          </animated.div>
        </div>
      </ContainerSm>
      {image && (
        <div className={cx(styles.imageContainer, styles.imageDesktop)}>
          <ImageCover
            layoutClassName={cx(styles.image)}
            {...{ image }}
            aspectRatio={16 / 9}
            sizes='100vw'
            imgProps={{ fetchpriority: 'high' }}
          />
        </div>
      )}
      {image && (
        <div className={cx(styles.imageContainer, styles.imageMobile)}>
          <ImageCover
            layoutClassName={cx(styles.image)}
            {...{ image }}
            aspectRatio={9 / 16}
            sizes='100vw'
            imgProps={{ fetchpriority: 'high' }}
          />
        </div>
      )}
      <div role='presentation' className={styles.gradient} />
    </header>
  )
}
